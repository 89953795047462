import { ProductType } from 'Enums/ProductType.enum';
import CampaignPageModel from 'Models/Pages/CampaignPage/CampaignPageModel.interface';
import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import LoadMoreContainer from 'Molecules/LoadMoreContainer/LoadMoreContainer';
import ProductCard from 'Organisms/ProductCard/ProductCard';
import FilterBar from 'Organisms/FilterComponent/FilterBar';
import { useQueryCategoryItems } from 'Pages/SearchPage/Search';
import React, { useEffect, useMemo } from 'react';
import { canUseDOM, isEmpty } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import {
  FilterProvider,
  useFilterData,
} from '../../Organisms/FilterComponent/FilterProvider';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';

const API_URL = 'GetCampaignItems';

function CampaignPageWrapper() {
  if (!canUseDOM()) {
    return <></>;
  }

  return (
    <FilterProvider urlSearchString={window?.location.search || ''}>
      <CategoryPage />
    </FilterProvider>
  );
}

const CAMPAIGN_LOADING = 'CampaignPage[INITIAL LOADING]';

function CategoryPage() {
  const { categoryLabels, commonLabels } = useTranslationData();
  const page = useCurrentPage();

  const [state, dispatch] = useFilterData();

  const {
    pageHeading,
    heroImage,
    inEditMode,
    heroDarkOverlay,
    promotions,
    shouldShowCoupon,
    validUntil,
  } = useCurrentPage<CampaignPageModel>();

  const [loadingState, loadingDispatch] = useKexLoadingCircle();

  const stateLoading = !!loadingState.has(CAMPAIGN_LOADING);

  const pagePath = page.breadcrumb[page.breadcrumb.length - 1]?.href || '';

  useEffect(() => dispatch({ type: 'onChangeCategory' }), [pagePath, dispatch]);

  const { facets, sorters, isLoading, result, paginate, loadingMore } =
    useQueryCategoryItems(API_URL, state.queryParams, pagePath);

  // clean up
  useEffect(() => {
    return () => {
      loadingDispatch({
        type: 'remove',
        from: CAMPAIGN_LOADING,
      });
    };
  }, [loadingDispatch]);

  useEffect(() => {
    if (isLoading && !stateLoading) {
      loadingDispatch({
        type: 'add',
        from: CAMPAIGN_LOADING,
      });
    } else if (!isLoading && stateLoading) {
      loadingDispatch({
        type: 'remove',
        from: CAMPAIGN_LOADING,
      });
    }
  }, [isLoading, stateLoading, loadingDispatch]);

  useEffect(
    () => facets && sorters && dispatch({ type: 'setFacets', facets, sorters }),
    [facets, sorters, dispatch]
  );

  const loadedPercentage = useMemo(() => {
    return result?.productSearchResult
      ? (result?.productSearchResult?.items.length /
          result?.productSearchResult?.availableItems) *
          100
      : 0;
  }, [result?.productSearchResult]);

  const counterText = useMemo(() => {
    return result?.productSearchResult
      ? categoryLabels.counter
          .replace('{0}', result.productSearchResult?.items.length.toString())
          .replace('{1}', result.productSearchResult?.availableItems.toString())
      : '';
  }, [result?.productSearchResult, categoryLabels.counter]);

  const productType =
    !isEmpty(result?.productSearchResult?.items) &&
    result?.productSearchResult?.items[0].productType;

  return (
    <>
      {heroImage && (
        <SmallHeroBlock
          header={pageHeading}
          inEditMode={inEditMode}
          image={heroImage}
          overlay={heroDarkOverlay}
        />
      )}
      <ContentContainer>
        <PromotionText>
          {!isEmpty(promotions) &&
            promotions
              .filter((p) => p.promotionName || p.couponCode)
              .map((p, i) => {
                return (
                  !!shouldShowCoupon && (
                    <React.Fragment key={i}>
                      {commonLabels.useCouponCode.replace(
                        '{0}',
                        p.promotionName
                      )}{' '}
                      <b>{p.couponCode}</b>. {commonLabels.validUntilDate}{' '}
                      {validUntil}
                    </React.Fragment>
                  )
                );
              })}{' '}
        </PromotionText>

        <FilterBar
          css={{ pb: 6 }}
          results={{
            facets: facets,
            productResults: result?.productSearchResult,
          }}
          counterText={counterText}
        />
      </ContentContainer>
      <ProductResults>
        <ContentContainer>
          {!isLoading ? (
            <>
              {productType === ProductType.MOTORCYCLES && (
                <MCStyledGrid>
                  {result?.productSearchResult?.items.map(
                    (product: ProductCardModel, index: number) => (
                      <MCProductCardWrapper key={index}>
                        <ProductCard item={product} />
                      </MCProductCardWrapper>
                    )
                  )}
                </MCStyledGrid>
              )}
              {productType !== ProductType.MOTORCYCLES && (
                <NonMCStyledGrid>
                  {result?.productSearchResult?.items.map((product, index) => (
                    <NonMCProductCardWrapper key={index}>
                      <ProductCard item={product} />
                    </NonMCProductCardWrapper>
                  ))}
                </NonMCStyledGrid>
              )}
            </>
          ) : (
            <Padding />
          )}
        </ContentContainer>

        {!isLoading && !!result && (
          <LoadMoreContainerWrapper>
            <LoadMoreContainer
              loaded={loadedPercentage}
              counterText={counterText}
              loadMore={categoryLabels.loadMore}
              disabled={
                result.productSearchResult?.items.length ===
                result.productSearchResult?.availableItems
              }
              onClick={paginate}
              isLoading={loadingMore}
            />
          </LoadMoreContainerWrapper>
        )}
      </ProductResults>
    </>
  );
}

const MCStyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '16px',
  maxWidth: 'auto',
  '@media(min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr',
    gap: '32px',
  },
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '32px',
  },
});

const MCProductCardWrapper = styled('div', {
  minWidth: '220px',
});

const NonMCStyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  maxWidth: 'auto',
  '@media(min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '32px',
  },
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '32px',
  },
});

const NonMCProductCardWrapper = styled('div', {
  minWidth: '140px',
});

const Padding = styled('div', {
  height: '400px',
});

const LoadMoreContainerWrapper = styled('div', {
  marginTop: '48px',
});

const ProductResults = styled('div', {
  backgroundColor: '$primary5',
  pt: 12,
});

const PromotionText = styled('span', {
  pt: 12,
  display: 'block',
});

export default CampaignPageWrapper;
